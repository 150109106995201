import React from "react"
import { graphql } from "gatsby"
import { MultiContentBlock } from "./MultiContentBlock"
import "./multi-content-blocks.module.scss"
import { SingleContentBlock } from "./multi-content-block"
import { HighlightsGroup } from "components/highlights/highlights-types"
import { MultiContentHighlights } from "components/highlights/MCB/MultiContentHighlights"

interface MultiContentBlocksProps {
  contentBlock: Array<SingleContentBlock | HighlightsGroup>
  heading?: string
  subHeading?: string
  layoutOverrideOptions?: string
}

export const MultiContentBlocks = ({
  heading,
  subHeading,
  contentBlock,
  layoutOverrideOptions = "",
}: MultiContentBlocksProps) => {
  const getMultiContentBlockClassNameByBlockCount = (
    input: number | string
  ) => {
    switch (input) {
      case 2:
      case "n2_columns":
        return "multi-content-block-wrapper-2-blocks"
      case 4:
      case "n4_columns":
        return "multi-content-block-wrapper-4-blocks"
      case 5:
        return "multi-content-block-wrapper-5-blocks"
      case 7:
        return "multi-content-block-wrapper-7-blocks"
      case "one_thirds_two_thirds_split":
        return "multi-content-block-wrapper-one-thirds-split"
      default:
        return "multi-content-block-wrapper-3-blocks"
    }
  }

  const blockCount = contentBlock.length

  const multiContentBlockClassName = layoutOverrideOptions
    ? getMultiContentBlockClassNameByBlockCount(layoutOverrideOptions)
    : getMultiContentBlockClassNameByBlockCount(blockCount)

  return (
    <section className="multi-content-blocks u-content-block-padding">
      <div className={`fr-container`}>
        {heading && (
          <div className="text-content-block__title">
            <h2>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        <div className={multiContentBlockClassName}>
          {contentBlock.map((component, i) => {
            // Prevent 1/3 and 2/3 blocks to load more than 2 blocks (since we can't limit a rich text in kentico)
            if (
              layoutOverrideOptions === "one_thirds_two_thirds_split" &&
              i > 1
            ) {
              return null
            }
            if (component?.system?.type === "highlights_group") {
              const highlightGroup = component as HighlightsGroup
              const highlights =
                highlightGroup?.elements?.highlights?.modular_content!

              if (highlights?.length > 0) {
                return (
                  <MultiContentHighlights
                    key={highlightGroup.id}
                    highlights={highlights}
                  />
                )
              }
            }
            if (component?.system?.type === "content_block") {
              const singleContentBlock = component as SingleContentBlock
              const contentBlock = singleContentBlock?.elements

              if (contentBlock) {
                return (
                  <MultiContentBlock
                    key={singleContentBlock.id}
                    contentBlock={contentBlock}
                  />
                )
              }
            }
            // Prevents this theme to break if a not supported but allowed type is added in kentico
            return null
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment MultiContentBlocksFragment on kontent_item_multi_content_block {
    id
    elements {
      multi_block_heading {
        value
      }
      multi_content_background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      sub_heading {
        value
      }
      layout_override_options {
        value {
          codename
        }
      }
      content_block {
        modular_content {
          ...ContentBlockFragment
          ...HighlightGroupFragment
        }
      }
    }
  }
`
