import React from "react"

interface CouponValueProps {
  value: string
  baseClassName: string
  hasDollarSignSuperset: boolean
}

export const CouponValue = ({
  value,
  baseClassName,
  hasDollarSignSuperset,
}: CouponValueProps) => {
  const valueEndsWithStar = value.endsWith("*")

  const getValue = (value: string) => {
    if (!valueEndsWithStar) return value
    const valueWithoutStar = value.replace("*", "")
    return (
      <>
        {valueWithoutStar}
        <sup className={`${baseClassName}__value--super-script-sign`}>*</sup>
      </>
    )
  }

  if (!hasDollarSignSuperset) {
    return <h2 className={`${baseClassName}__value`}>{getValue(value)}</h2>
  }

  const isDollarSignFirstCharacter = value.indexOf("$") === 0

  if (isDollarSignFirstCharacter) {
    return (
      <h2
        className={`${baseClassName}__value ${baseClassName}__value--super-script-first-letter`}
      >
        {getValue(value)}
      </h2>
    )
  }

  const [couponValueBeforeDollarSign, couponValueAfterDollarSign] =
    value.split("$")

  return (
    <h2
      className={`${baseClassName}__value ${baseClassName}__value--super-script-heading`}
    >
      {couponValueBeforeDollarSign}
      <sup className={`${baseClassName}__value--super-script-sign`}>$</sup>
      {getValue(couponValueAfterDollarSign)}
    </h2>
  )
}
