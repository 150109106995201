import React from "react"
import { FAQGroupBanner } from "components/faq/FAQGroupBanner"
import { FAQGroupBlock } from "components/faq/FAQGroupBlock"
import { IFAQGroup } from "../content-blocks-types"

export const getFAQGroupBanner = (
  blocks: IFAQGroup,
  hasAltBackgroundColor: boolean
) => {
  const { faq_group, eyebrow_text, heading, sub_heading } = blocks
  if (!faq_group || faq_group?.modular_content?.length === 0) return ""

  return (
    <FAQGroupBanner
      heading={heading?.value}
      sub_heading={sub_heading?.value}
      eyebrowText={eyebrow_text?.value}
      hasAltBackgroundColor={hasAltBackgroundColor}
    >
      {faq_group.modular_content.map(({ elements, id }) => {
        const { faqs, is_faq_item_collapsable } = elements
        const faqsList = faqs?.modular_content
        const isCollapsable =
          is_faq_item_collapsable?.value[0]?.codename === "yes"

        if (faqsList?.length > 0) {
          return (
            <FAQGroupBlock
              key={id}
              faqs={faqsList}
              isCollapsable={isCollapsable}
            />
          )
        }
      })}
    </FAQGroupBanner>
  )
}
