import React from "react"
import { graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import { Highlight } from "../Highlight"
import { SingleHighlight } from "../highlights-types"
import { Slider } from "./Slider"
import "./highlights.module.scss"
import "./highlights-slider.module.scss"
import { useWindowDimensions } from "hooks/useWindowDimensions"

interface HighlightsProps {
  heading?: string
  subHeading?: string
  highlights: SingleHighlight[]
  hasAltBackgroundColor: boolean
}

export const Highlights = ({
  heading,
  subHeading,
  highlights,
  hasAltBackgroundColor,
}: HighlightsProps) => {
  const { size } = useWindowDimensions()

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  })
  const isStyledAsPill = highlights?.some(
    highlight => !!highlight?.elements?.counter_end_value?.value
  )
  const rowStyledAsPill = isStyledAsPill
    ? "highlights__wrapper--row-as-pill"
    : ""

  const rowStyledAsPillMobile = isStyledAsPill
    ? "highlights-slider__wrapper--row-as-pill"
    : ""

  const noPaddingTop = !heading ? "highlights--no-padding-top" : ""

  const wrapperModifierOddOrEven =
    highlights.length % 2
      ? "highlights__wrapper--odd"
      : "highlights__wrapper--even"

  const wrapperHighDensity =
    highlights.length > 4 ? "highlights__wrapper--high-desity" : ""

  const highlightsSliderSettings = {
    dotsClass: "slick-dots highlights-slider__slick-dots",
    className: "highlights-slider__content",
  }

  const getHighlights = () => {
    return highlights.map(({ elements, id }) => {
      const {
        heading,
        caption,
        svg_icon,
        image,
        counter_start_value,
        counter_end_value,
        animated_counter,
        special_character,
      } = elements

      return (
        <Highlight
          title={heading?.value}
          image={image?.value[0]}
          caption={caption?.value}
          key={id}
          rightSvg={svg_icon?.value}
          counterStartValue={counter_start_value?.value}
          counterEndValue={counter_end_value?.value}
          inView={inView}
          baseClassName="highlights"
          isAnimated={animated_counter?.value[0]?.codename === "yes"}
          specialCharacter={special_character?.value[0]?.name}
        />
      )
    })
  }

  return (
    <section
      className={`u-content-block-padding ${
        hasAltBackgroundColor ? "alternate-background-color" : ""
      } highlights ${noPaddingTop}`}
    >
      <div className="fr-container u-clearfix">
        {heading && (
          <div className="text-content-block__title">
            <h2>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        {size >= 1024 && (
          <div
            className={`highlights__wrapper ${wrapperModifierOddOrEven} ${wrapperHighDensity} ${rowStyledAsPill}`}
            ref={ref}
          >
            {getHighlights()}
          </div>
        )}
        {size > 0 && size < 1024 && (
          <div
            className={`highlights-slider__wrapper ${rowStyledAsPillMobile}`}
          >
            <Slider settings={highlightsSliderSettings}>
              {getHighlights()}
            </Slider>
          </div>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HighlightGroupFragment on kontent_item_highlights_group {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      highlights {
        modular_content {
          ...HighlightFragment
        }
      }
    }
  }
`
