import React from "react"
import { Text } from "_types/Text"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"

type Tab = {
  id: string
  elements: {
    tab_title: Text
    svg_icon?: Text
  }
}

interface TabsProps {
  tabbedContent?: any
  selectedServiceIndex?: number
  setSelectedService?: (selectedServiceId: number) => void
  className?: string
  showTabTitleIcon?: boolean
  hasSliderIndicatorBar?: boolean
  sliderBarDirection?: "horizontal" | "vertical"
}

export const Tabs = ({
  className = "fr-tabs-controller",
  showTabTitleIcon = false,
  hasSliderIndicatorBar = false,
  sliderBarDirection = "horizontal",
}: TabsProps) => {
  const { activeTabIndex, handleChangeActiveTab, allContent } =
    useTabbedContainer<Tab>()

  const sliderIndicatorStyles =
    sliderBarDirection === "vertical"
      ? {
          transform: `translateY(calc(100%/${allContent.length}*${activeTabIndex}))`,
        }
      : {
          width: `calc(100%/${allContent.length})`,
          transform: `translateX(calc(100%*${activeTabIndex}))`,
        }

  return (
    <div className={className}>
      <div className={`${className}__tabs-wrapper`}>
        {allContent.map(({ id, elements }, index) => {
          const tabTitle = elements.tab_title.value
          const inlineSVGIcon = elements?.svg_icon?.value
          return (
            <button
              onClick={() => handleChangeActiveTab(index)}
              key={id}
              className={`${className}__tab${
                activeTabIndex === index ? ` ${className}__tab--active` : ""
              }`}
            >
              {showTabTitleIcon && inlineSVGIcon && (
                <span
                  className={`${className}__tab-icon`}
                  contentEditable="false"
                  dangerouslySetInnerHTML={{ __html: inlineSVGIcon }}
                />
              )}
              {tabTitle}
            </button>
          )
        })}
      </div>
      {hasSliderIndicatorBar && (
        <div
          className={`${className}__tabs-bottom-slider`}
          style={sliderIndicatorStyles}
        >
          <div className={`${className}__tabs-indicator`}></div>
        </div>
      )}
    </div>
  )
}
