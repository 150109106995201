import React, { ReactNode } from "react"
import { graphql } from "gatsby"
import { Content } from "../content/Content"
import { Image } from "../image/Image"
import "./content-block.module.scss"
import { ImageBuilder } from "_types/AssetsImage"
import { RichTextContent } from "_types/RichText"
import { MultipleChoice } from "_types/MultipleChoice"
import { ButtonBuilder } from "_types/Button"
import { ButtonParser } from "components/button/ButtonParser"

interface TextAndImageContentBlockProps {
  content?: RichTextContent
  eyebrowText?: string
  heading?: string
  subHeading?: string
  image?: ImageBuilder
  hasAltBackgroundColor?: boolean
  buttons?: ButtonBuilder[]
  mapRuns?: number
  isAltBlock?: boolean
  contentBlockType?: MultipleChoice
  children?: ReactNode
  styleListsAsGrid?: boolean
  hasBlockPadding?: boolean
  hasContainerClassName?: boolean
}

export const TextAndImageContentBlock = ({
  content,
  eyebrowText,
  heading,
  subHeading,
  image,
  hasAltBackgroundColor,
  buttons,
  mapRuns,
  isAltBlock,
  contentBlockType,
  children,
  styleListsAsGrid = false,
  hasBlockPadding = true,
  hasContainerClassName = true,
}: TextAndImageContentBlockProps) => {
  let blockType = contentBlockType?.value[0]?.codename || "text_wraps_image"

  let oddOrEven = ""

  const contentNotEmpty = content?.value === "<p><br></p>" ? false : true

  if (isAltBlock && mapRuns) {
    oddOrEven = mapRuns % 2 ? "even" : "odd"
  }

  const alternatingClassName = isAltBlock
    ? ` ${blockType}--${oddOrEven}`
    : `${blockType}--odd`

  const contentBlockPaddingClassName = hasBlockPadding
    ? ` u-content-block-padding`
    : ""

  const containerClassName = hasContainerClassName ? `fr-container` : ""

  const alternateBackground = hasAltBackgroundColor
    ? " alternate-background-color"
    : ""

  const getButtonsClassName = (blockType: string) => {
    switch (blockType) {
      case "fifty_fifty_grid":
      case "one_thirds_two_thirds_split":
      case "one_eighth_seven_eighths_split":
        return "content-block-buttons content-block-buttons--start"
      default:
        return "content-block-buttons"
    }
  }
  const buttonParserClassName = getButtonsClassName(blockType)

  return (
    <section
      className={`${blockType} ${alternatingClassName} ${alternateBackground} ${contentBlockPaddingClassName} u-clearfix`}
    >
      <div className={containerClassName}>
        {heading && (
          <div className={`${blockType}__title`}>
            {eyebrowText && <p className={`eyebrow-text`}>{eyebrowText}</p>}
            {<h2>{heading}</h2>}
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        {image && (
          <div className={`${blockType}__image`}>
            <Image image={image} alt={image.description} width={652} />
          </div>
        )}
        <div className={`${blockType}__content`}>
          {content && contentNotEmpty && (
            <Content content={content} styleListsAsGrid={styleListsAsGrid} />
          )}
          {buttons && buttons.length > 0 && (
            <div className={buttonParserClassName}>
              <ButtonParser
                buttons={buttons}
                primaryColorVariant="outline-secondary"
                secondaryColorVariant="solid-primary"
              />
            </div>
          )}
        </div>
        {children}
      </div>
    </section>
  )
}
export const query = graphql`
  fragment ContentBlockFragment on kontent_item_content_block {
    system {
      type
    }
    id
    elements {
      eyebrow_text {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
      }
      content {
        value
        links {
          link_id
          url_slug
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      buttons {
        value {
          ...AnchorLinkFragment
          ...ButtonFragment
        }
      }
      style_lists_as_grid {
        value {
          codename
        }
      }
      content_block_type {
        value {
          codename
        }
      }
      image_gallery {
        value {
          ...GalleryFragment
        }
      }
    }
  }
`
