import React from "react"
import { truncString } from "assets/helper/truncString"
import { Image } from "components/image/Image"
import { NavigationTileElements, TileElements } from "./navigation-tile-type"

interface TileContentProps {
  tile: NavigationTileElements | TileElements
  baseClassName: string
}

export const TileContent = ({ tile, baseClassName }: TileContentProps) => {
  const { background_image, cta, heading, description } = tile

  return (
    <>
      {background_image && background_image?.value.length > 0 && (
        <Image
          image={background_image?.value[0]}
          className={`${baseClassName}__image`}
          alt={background_image?.value[0]?.description}
          objectFit="contain"
        />
      )}
      <div className={`${baseClassName}__content`}>
        {heading?.value && (
          <h3 className={`${baseClassName}__title`}>
            {truncString(heading?.value, 155)}
          </h3>
        )}

        <div className={`${baseClassName}__action-container`}>
          <div className={`${baseClassName}__action`}>
            {description?.value && (
              <p className={`${baseClassName}__description u-text--align-left`}>
                {truncString(description.value, 155)}
              </p>
            )}
            {cta?.value && (
              <div className={`${baseClassName}__cta-wrapper`}>
                <div className={`${baseClassName}__cta`}>
                  <span>{cta.value}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
