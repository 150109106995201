import React from "react"
import { Button } from "components/button"
import { ExternalLink } from "components/external-link/ExternalLink"
import { LinkedPage } from "_types/LinkedPage"
import { Text } from "_types/Text"

interface CallOfActionProps {
  button_text?: Text
  external_link?: Text
  linked_page?: LinkedPage
  baseClassName: string
}

export const CallOfAction = ({
  button_text,
  external_link,
  linked_page,
  baseClassName,
}: CallOfActionProps) => {
  const slug = linked_page
    ? linked_page.value[0].elements.slug.value!
    : external_link?.value!
  if (linked_page) {
    return (
      <Button
        to={slug?.startsWith("/") ? slug : `/${slug}`}
        variant="solid-primary"
        extraClassNames={`${baseClassName}__button`}
      >
        {button_text?.value}
      </Button>
    )
  }
  return (
    <ExternalLink
      href={external_link?.value}
      className={`button--solid-primary ${baseClassName}__button`}
    >
      {button_text?.value}
    </ExternalLink>
  )
}
