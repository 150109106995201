import { graphql } from "gatsby"
import React from "react"

interface FAQGroupBannerProps {
  heading?: string
  sub_heading?: string
  eyebrowText?: string
  hasAltBackgroundColor?: boolean
  children: React.ReactNode
}

export const FAQGroupBanner = ({
  heading,
  sub_heading,
  eyebrowText,
  hasAltBackgroundColor = false,
  children,
}: FAQGroupBannerProps) => {
  const altBgColor = hasAltBackgroundColor ? " alternate-background-color" : ""

  return (
    <section
      className={`fr-group-banner u-content-block-padding ${altBgColor}`}
    >
      <div className="fr-container">
        {(heading || sub_heading) && (
          <div className="text-content-block__title">
            {eyebrowText && <p className={`eyebrow-text`}>{eyebrowText}</p>}
            {heading && <h2>{heading}</h2>}
            {sub_heading && <h3>{sub_heading}</h3>}
          </div>
        )}
        {children}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment FAQGroupBannerFragment on kontent_item_faq_group_banner {
    system {
      type
    }
    id
    elements {
      faq_group {
        modular_content {
          ...FAQGroupBlockFragment
        }
      }
      heading {
        value
      }
      sub_heading {
        value
      }
      eyebrow_text {
        value
      }
    }
  }
`
