import React from "react"
import { Link, graphql } from "gatsby"
import { useLayout } from "contexts/components/LayoutContext"
import { NavigationTileElements } from "./navigation-tile-type"
import { TileOverlay } from "./TileOverlay"
import { TileContent } from "./TileContent"

interface NavigationTileProps {
  tile: NavigationTileElements
  isDense?: boolean
  hasOverlay?: boolean
}

export const NavigationTile = ({
  tile,
  hasOverlay = false,
  isDense = false,
}: NavigationTileProps) => {
  const { internal_link } = tile

  const slug = internal_link?.value[0]?.elements?.slug?.value
  const { linkedPagesUrlPathCodex } = useLayout()
  const path = linkedPagesUrlPathCodex[slug] || "404"
  const navSlug = path.startsWith("/") ? path : `/${path}`
  const isDenseClassName = isDense ? "fr-tile--dense" : ""

  return (
    <Link to={navSlug} className={`fr-tile nav-tile ${isDenseClassName}`}>
      {hasOverlay && <TileOverlay baseClassName="fr-tile" />}
      <TileContent baseClassName="fr-tile" tile={tile} />
    </Link>
  )
}

export const query = graphql`
  fragment NavigationTileFragment on kontent_item_navigation_tile {
    id
    elements {
      heading {
        value
      }
      description {
        value
      }
      cta {
        value
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      internal_link {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
    }
  }
`
