import React, { ReactNode } from 'react'

interface FAQAnswerProps {
  children: ReactNode
}

export const FAQAnswer = ({children}: FAQAnswerProps) => {
  return (
    <div
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
      <div itemProp="text">
        {children} 
      </div>
    </div>
  )
}