import React from "react"

interface FAQPageWrapperProps {
  children: React.ReactNode
  isFAQPage: boolean
}

export const FAQPageWrapper = ({
  children,
  isFAQPage,
}: FAQPageWrapperProps) => {
  if (isFAQPage) {
    return (
      <article itemScope itemType="https://schema.org/FAQPage">
        <>{children}</>
      </article>
    )
  }
  return <article>{children}</article>
}