import React, { ReactNode } from "react"
import { graphql } from "gatsby"
import { ImageBuilder } from "../../../_types/AssetsImage"
import { Image } from "../../image/Image"

interface FaqProps {
  children: ReactNode
  image?: ImageBuilder
}

export const FAQ = ({ children, image }: FaqProps) => {
  return (
    <div
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      className="fr-faq-group-block__wrapper"
    >
      {children}
      
      {image && (
        <Image
          image={image}
          className="fr-faq-group-block__image"
          alt={image.description}
          width={image.width}
        />
      )}
    </div>
  )
}

export const query = graphql`
fragment FAQFragment on kontent_item_faq {
  id
  elements {
    image {
      value {
        ...KenticoAssetElementFragment
      }
    }
    question {
      value
    }
    answer {
      value
      links {
        link_id
        url_slug
      }
    }
  }
}
`