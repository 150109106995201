import React from "react"
import { Block, IContentBlock, ISingleService } from "./content-blocks-types"
import { ContentBlockParser } from "./ContentBlockParser"
import { FAQPageWrapper } from "components/faq/FAQPageWrapper"

type Content = {
  id: string
  elements: Block
}

interface ContentBlocksProps {
  content: Content[]
  legacyNavGridHasAltBackground?: boolean
  hasAlternatingContentBlocks?: boolean
}

export const ContentBlocks = ({
  content,
  hasAlternatingContentBlocks = true,
  legacyNavGridHasAltBackground = false,
}: ContentBlocksProps) => {
  let mapRunsAlt = 0 // keeps track of how many content blocks that need to be have alternating flex direction
  let serviceCount = 0
  const filteredContentBlocks = content.filter(
    block => Object.keys(block).length !== 0
  )
  const numBlocks = filteredContentBlocks.length
  const isFAQPage = filteredContentBlocks.some(
    ({ system }) => system?.type === "faq_group_banner"
  )
  const getIsAltBlock = (
    hasAlternatingContentBlocks: boolean,
    elements: IContentBlock
  ) => {
    if (!hasAlternatingContentBlocks) return false

    const isContentEmpty = elements?.content?.value.toString() === "<p><br></p>"
    if (isContentEmpty) return false

    const hasImage = elements?.image && elements?.image?.value?.length > 0
    if (!hasImage) return false

    const hasContentBlockType =
      elements?.content_block_type &&
      elements?.content_block_type?.value.length > 0
    if (!hasContentBlockType) return false

    const isTextWrapsImage =
      elements?.content_block_type?.value[0]?.codename === "text_wraps_image"
    if (isTextWrapsImage) return false

    return true
  }

  return (
    <FAQPageWrapper isFAQPage={isFAQPage}>
      {filteredContentBlocks.map(({ id, elements }, i) => {
        if (!elements) return

        let isAltBlock = getIsAltBlock(
          hasAlternatingContentBlocks,
          elements as IContentBlock
        )

        if (isAltBlock) mapRunsAlt++

        if ((elements as ISingleService).service_features) serviceCount++

        return (
          <React.Fragment key={id}>
            <ContentBlockParser
              blocks={elements}
              position={i}
              blockCount={numBlocks}
              mapRuns={mapRunsAlt}
              isAltBlock={isAltBlock}
              serviceCount={serviceCount}
              legacyNavGridHasAltBackground={legacyNavGridHasAltBackground}
            />
          </React.Fragment>
        )
      })}
    </FAQPageWrapper>
  )
}
