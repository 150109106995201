import React from "react"
import { graphql } from "gatsby"
import { SingleContentTile } from "./navigation-tile-type"
import "./navigation-tile.module.scss"
import { Tile } from "./Tile"

interface ContentTilesProps {
  tiles: SingleContentTile[]
  hasAltBackgroundColor: boolean
}

export const ContentTiles = ({
  tiles,
  hasAltBackgroundColor,
}: ContentTilesProps) => {
  // Content tiles are a specific type of content block that is returned by Content blocks.
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  return (
    <section
      className={`u-content-block-padding u-clearfix content-tiles ${alternateBackground}`}
    >
      <div className={`fr-container fr-tiles-container`}>
        <div className={`content-tiles__wrapper`}>
          {tiles.map(({ id, elements }) => {
            if (!elements) return
            return <Tile key={id} tile={elements} />
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ContentTilesFragment on kontent_item_tile_block {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      tiles {
        modular_content {
          ...TileFragment
        }
      }
    }
  }
`

export const tilesBlockQuery = graphql`
  fragment TilesFragment on kontent_item_tiles_block {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      tiles {
        modular_content {
          ...TileFragment
        }
      }
    }
  }
`
