import React from "react"
import "./service.module.scss"
import { graphql } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"

import { ServiceElements } from "./service-types"
import { ButtonParser } from "components/button/ButtonParser"
import { CmsAssetImage } from "_types/AssetsImage"
import { Image } from "components/image/Image"

import { PriceTier } from "./PriceTier"

interface ServiceProps extends ServiceElements {
  image?: CmsAssetImage
  index: number
  atLeastOneHasFlag?: boolean
}

export const Service = ({
  index,
  flag,
  name,
  service_features,
  price_tiers,
  price_term,
  icon,
  button,
  pricing_disclaimer,
  atLeastOneHasFlag = false,
}: ServiceProps) => {
  return (
    <div
      className={`service ${
        atLeastOneHasFlag ? "service--at-least-one-with-flag" : ""
      }`}
    >
      {flag && flag.value.length > 0 && (
        <div className="service__flag">
          <p>{flag.value}</p>
        </div>
      )}
      {icon?.value[0] && (
        <Image
          image={icon.value[0]}
          height={195}
          className="service__icon"
          objectFit="contain"
        />
      )}
      {name?.value && (
        <div className="service__header">
          <h3>{name.value}</h3>
        </div>
      )}
      {price_term &&
        price_term?.value.length > 0 &&
        price_tiers &&
        price_tiers?.value.length > 0 && (
          <PriceTier
            priceTier={price_tiers.value[0].elements}
            priceTerm={price_term.value[0].codename}
            isServiceComparison={true}
          />
        )}
      {service_features?.value && service_features?.value !== "<p><br></p>" && (
        <div className="service__body">
          <span>
            <RichTextElement value={service_features.value} />
          </span>
        </div>
      )}
      <div
        className={
          index === 1
            ? `service__footer service__footer--primary`
            : `service__footer `
        }
      >
        {button && button.value?.length > 0 && (
          <div className="content-block-buttons">
            <ButtonParser
              buttons={button.value}
              primaryColorVariant={"outline-ghost"}
            />
          </div>
        )}

        {pricing_disclaimer && (
          <div className="service__disclaimer">
            <p>{pricing_disclaimer.value}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ServiceFragment on kontent_item_service {
    id
    elements {
      flag {
        value
      }
      name {
        value
      }
      service_features {
        value
        links {
          link_id
          url_slug
        }
      }
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      image_gallery {
        modular_content {
          ...GalleryFragment
        }
      }
      pests_covered {
        value
        links {
          link_id
          url_slug
        }
      }
      price {
        value
      }
      price_term {
        value {
          codename
        }
      }
      pricing_disclaimer {
        value
      }
      button {
        value {
          ...AnchorLinkFragment
          ...ButtonFragment
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      price_tiers {
        value {
          ... on kontent_item_service_price_tier {
            id
            elements {
              ending_footage {
                value
              }
              footage_type {
                value {
                  codename
                }
              }
              initial_fee {
                value
              }
              price {
                value
              }
              starting_footage {
                value
              }
            }
          }
        }
      }
    }
  }
`
