import React from "react"
import { graphql } from "gatsby"
import { Content } from "components/content/Content"
import { RichTextContent } from "_types/RichText"
import { ButtonElements } from "_types/Button"
import { useLogos } from "hooks/queries/settings/useLogos"
import { Image } from "components/image/Image"
import { CouponValue } from "./CouponValue"
import { CallOfAction } from "./CallOfAction"

interface CouponProps {
  couponValue?: string
  subHeading?: string
  description?: RichTextContent
  button?: ButtonElements
  disclaimer?: string
  endDate?: string
  hideLogo?: boolean
  couponFlag?: string
  baseClassName: string
  hasDollarSignSuperset?: boolean
}

export const Coupon = ({
  couponValue,
  subHeading,
  description,
  button,
  disclaimer,
  endDate,
  hideLogo = false,
  couponFlag,
  baseClassName,
  hasDollarSignSuperset = false,
}: CouponProps) => {
  const { headerLogo } = useLogos()

  return (
    <div
      className={`${baseClassName} ${
        couponFlag ? `${baseClassName}--has-flag` : ""
      }`}
    >
      {couponFlag && (
        <div className={`${baseClassName}__flag-container`}>
          <p>{couponFlag}</p>
        </div>
      )}
      <div className={`${baseClassName}__header`}>
        {couponValue && (
          <CouponValue
            baseClassName={baseClassName}
            value={couponValue}
            hasDollarSignSuperset={hasDollarSignSuperset}
          />
        )}
        {subHeading && (
          <h3 className={`${baseClassName}__sub-heading`}>{subHeading}</h3>
        )}
        {description && <Content content={description} />}
      </div>
      <div className={`${baseClassName}__footer`}>
        {button && <CallOfAction {...button} baseClassName={baseClassName} />}
        {!hideLogo && (
          <Image image={headerLogo} className={`${baseClassName}__logo`} />
        )}
        <div className={`${baseClassName}__disclaimer`}>
          {endDate && !disclaimer && <p>Offer Expires: {endDate}</p>}
          {disclaimer && !endDate && <p>{disclaimer}</p>}
          {endDate && disclaimer && (
            <p>
              Offer Expires: {endDate}. {disclaimer}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment CouponFragment on kontent_item_coupon {
    system {
      type
    }
    id
    elements {
      coupon_value {
        value
      }
      sub_heading {
        value
      }
      description {
        value
      }
      hide_logo {
        value {
          codename
        }
      }
      button {
        value {
          ...ButtonFragment
        }
      }
      offer_end_date {
        value
      }
      disclaimer {
        value
      }
      coupon_flag {
        value
      }
    }
  }
`
