import React from "react"
import { graphql } from "gatsby"
import { Content } from "components/content/Content"
import { Coupon } from "./Coupon"
import { RichTextContent } from "_types/RichText"
import { CouponType } from "./coupons"
import "./coupons.module.scss"

interface CouponBannerProps {
  heading?: string
  subHeading?: string
  eyebrowText?: string
  description?: RichTextContent
  coupons: CouponType[]
  hasAltBackgroundColor: boolean
}

const COUPON_CLASSNAME: { [key: number]: string } = {
  1: "coupon-banner__grid--1",
  2: "coupon-banner__grid--2",
  3: "coupon-banner__grid--3",
}

export const CouponBanner = ({
  heading,
  subHeading,
  eyebrowText,
  description,
  coupons,
  hasAltBackgroundColor,
}: CouponBannerProps) => {
  return (
    <section
      className={
        hasAltBackgroundColor
          ? "u-content-block-padding alternate-background-color"
          : "u-content-block-padding"
      }
    >
      <div className="fr-container">
        <div className="coupon__heading-container">
          {eyebrowText && <p className="eyebrow-text">{eyebrowText}</p>}
          {heading && <h2>{heading}</h2>}
        </div>

        <div className={COUPON_CLASSNAME[coupons.length]}>
          <div className="coupon-banner__info">
            {subHeading && subHeading !== "<p><br></p>" && (
              <div className="text-content-block__title">
                <h3>{subHeading}</h3>
              </div>
            )}
            {description && <Content content={description} />}
          </div>

          {coupons.map(({ elements, id }) => {
            const {
              coupon_value,
              sub_heading,
              description,
              offer_end_date,
              disclaimer,
              button,
              hide_logo,
              coupon_flag,
            } = elements

            return (
              <Coupon
                key={id}
                couponFlag={coupon_flag?.value}
                couponValue={coupon_value?.value}
                subHeading={sub_heading?.value}
                description={description}
                disclaimer={disclaimer?.value}
                button={button.value[0].elements}
                endDate={offer_end_date?.value}
                hideLogo={hide_logo?.value[0]?.codename === "yes"}
                baseClassName="coupon"
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CouponBannerFragment on kontent_item_coupon_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      eyebrow_text {
        value
      }
      description {
        value
        links {
          link_id
          url_slug
        }
      }
      coupons {
        modular_content {
          ...CouponFragment
        }
      }
      alt_background_color {
        value {
          codename
        }
      }
    }
  }
`
