import React from "react"
import { graphql } from "gatsby"
import { Service } from "./Service"
import { IService } from "./service-types"
import "./service-comparison.module.scss"

interface ServiceComparisonProps {
  heading?: string
  subHeading?: string
  hasAltBackgroundColor: boolean
  services: IService[]
  disclaimer?: string
}

export const ServiceComparison = ({
  heading,
  subHeading,
  hasAltBackgroundColor,
  services,
  disclaimer,
}: ServiceComparisonProps) => {
  const atLeastOneServiceHasAFlag = services.some(
    ({ elements }) => elements?.flag && elements?.flag.value.length > 0
  )

  return (
    <section
      className={
        hasAltBackgroundColor
          ? "service-comparison--alt u-content-block-padding"
          : "service-comparison u-content-block-padding"
      }
    >
      <div className={`fr-container fr-container--large`}>
        {heading && (
          <div className="text-content-block__title">
            <h2>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}

        <div className="service-comparison__grid">
          {services.map(({ elements, id }, i) => {
            return (
              <Service
                {...elements}
                key={id}
                index={i}
                atLeastOneHasFlag={atLeastOneServiceHasAFlag}
              />
            )
          })}
        </div>

        {disclaimer && (
          <p className="service-comparison__disclaimer">{disclaimer}</p>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ServiceComparisonFragment on kontent_item_service_comparison {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      services {
        modular_content {
          ...ServiceFragment
        }
      }
      disclaimer {
        value
      }
    }
  }
`
