import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"

interface HighlightProps {
  image?: ImageBuilder
  caption?: string
  title?: string
  leftSvg?: string
  rightSvg?: string
  counterStartValue?: number
  counterEndValue?: number
  inView?: boolean
  baseClassName: string
  isAnimated?: boolean
  specialCharacter?: string
}

export const Highlight = ({
  image,
  caption,
  title,
  leftSvg,
  rightSvg,
  baseClassName,
  specialCharacter,
  counterStartValue = 0,
  counterEndValue = 0,
  inView = false,
  isAnimated = false,
}: HighlightProps) => {
  const [counter, setCounter] = useState(() => {
    return isAnimated ? counterStartValue : counterEndValue
  })
  const counterLength = specialCharacter
    ? String(counterEndValue).length + 1
    : String(counterEndValue).length

  const counterMinSizeCSSVariable = {} as { [key: string]: number }

  counterMinSizeCSSVariable[`--highlight-counter-size`] = counterLength

  const fadeInSpecialCharacterClassModifier =
    counter === counterEndValue
      ? `${baseClassName}__special-character--fade-in`
      : ""

  useEffect(() => {
    if (!isAnimated) return
    if (!counterEndValue) return

    let mounted = true

    const countdown = (counterStartValue: number, counterEndValue: number) => {
      const count = counterStartValue
      const increment = counterEndValue / 250
      if (count === counterEndValue || count > counterEndValue) {
        setCounter(counterEndValue)
        return
      }
      const updatedCounter = Math.ceil(count + increment)
      setCounter(updatedCounter)
    }

    if (!inView) return

    if (counter === counterEndValue) return

    setTimeout(() => {
      if (mounted) {
        countdown(counter, counterEndValue)
      }
    }, 3)

    return () => {
      mounted = false
    }
  }, [counter, counterEndValue, inView, counterStartValue, isAnimated])

  return (
    <div
      className={`${baseClassName}__child`}
      style={counterMinSizeCSSVariable}
    >
      {image && !leftSvg && !rightSvg && (
        <Image
          image={image}
          width={image.width}
          height={image.height}
          alt={image.description}
          className={`${baseClassName}__img`}
        />
      )}

      {(leftSvg || counter > 0 || rightSvg) && (
        <div
          className={`${baseClassName}__row ${
            counter > 0 ? `${baseClassName}__row--counter` : ""
          }`}
        >
          {leftSvg && (
            <div
              className={`${baseClassName}__icon`}
              dangerouslySetInnerHTML={{ __html: leftSvg }}
              contentEditable="false"
            ></div>
          )}

          {counter > 0 && (
            <h2 className={`${baseClassName}__counter`}>
              {counter}
              {specialCharacter && (
                <span
                  className={`${baseClassName}__special-character ${fadeInSpecialCharacterClassModifier}`}
                >
                  {specialCharacter}
                </span>
              )}
            </h2>
          )}
          {rightSvg && (
            <div
              className={`${baseClassName}__icon`}
              dangerouslySetInnerHTML={{ __html: rightSvg }}
              contentEditable="false"
            ></div>
          )}
        </div>
      )}

      {title && <h4 className={`${baseClassName}__heading`}>{title}</h4>}
      {caption && (
        <div
          className={
            counterEndValue
              ? `${baseClassName}__text`
              : `${baseClassName}__caption`
          }
          dangerouslySetInnerHTML={{ __html: caption }}
          contentEditable="false"
        ></div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment HighlightFragment on kontent_item_highlight {
    system {
      name
    }
    id
    elements {
      caption {
        value
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      svg_icon {
        value
      }
      heading {
        value
      }
      counter_start_value {
        value
      }
      counter_end_value {
        value
      }
      special_character {
        value {
          name
        }
      }
      animated_counter {
        value {
          codename
        }
      }
    }
  }
`
