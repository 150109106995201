import React from "react"
import { graphql } from "gatsby"
import { TileElements } from "./navigation-tile-type"
import { TileOverlay } from "./TileOverlay"
import { TileContent } from "./TileContent"

interface TileProps {
  tile: TileElements
  isDense?: boolean
  hasOverlay?: boolean
}

export const Tile = ({
  tile,
  hasOverlay = false,
  isDense = false,
}: TileProps) => {
  const isDenseClassName = isDense ? "content-tile--dense" : ""

  return (
    <div className={`fr-tile ${isDenseClassName}`}>
      {hasOverlay && <TileOverlay baseClassName="fr-tile" />}
      <TileContent tile={tile} baseClassName="fr-tile" />
    </div>
  )
}

export const query = graphql`
  fragment TileFragment on kontent_item_tile {
    id
    elements {
      heading {
        value
      }
      description {
        value
      }
      cta {
        value
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`
