import React, { useState } from "react"
import Slider from "react-slick"
import { TabContentBlockItem } from "./tabbed-content-block-types"
import { SingleService } from "components/service-comparison/SingleService"
import { TextAndImageContentBlock } from "components/content-blocks/TextAndImageContentBlock"
import { SampleNextArrow, SamplePrevArrow } from "./SliderArrow"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./tabbed-content-slider.module.scss"
import { ServiceElements } from "components/service-comparison/service-types"
import { IContentBlock } from "components/content-blocks/content-blocks-types"

interface TabbedContentSliderProps {
  content: TabContentBlockItem[]
  hasAltBackgroundColor: boolean
}

export const TabbedContentSlider = ({
  content,
  hasAltBackgroundColor,
}: TabbedContentSliderProps) => {
  const [nav1, setNav1] = useState<Slider>()
  const [nav2, setNav2] = useState<Slider>()

  const tabOverlayVariation = hasAltBackgroundColor ? "-altBg" : ""

  const tabSliderClassName =
    content.length <= 3
      ? `fr-tabbed-content-slider__tabs fr-tabbed-content-slider__tabs--justifyContentCenter`
      : "fr-tabbed-content-slider__tabs"

  const tabSettings = {
    dots: false,
    className: tabSliderClassName,
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const contentSettings = {
    dots: true,
    dotsClass: "slick-dots fr-tabbed-content-slider__slick-dots",
    className: "fr-tabbed-content-slider__content",
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: false,
    speed: 500,
  }

  return (
    <>
      <div className="fr-tabbed-content-slider__tabs-wrapper">
        <span
          className={`fr-tabbed-content-slider__tabs-gradient-overlay fr-tabbed-content-slider__tabs-gradient-overlay--left${tabOverlayVariation}`}
        />
        <Slider
          asNavFor={nav2}
          {...tabSettings}
          ref={slider1 => slider1 && setNav1(slider1)}
        >
          {content.map(({ elements, id }) => {
            if (!elements) return
            const { tab_title, svg_icon } = elements
            return (
              <div key={id} style={{ width: svg_icon?.value ? 350 : 300 }}>
                <div className="fr-tabbed-content-slider__single-tab">
                  <strong>{tab_title?.value}</strong>
                  {svg_icon?.value && (
                    <span
                      contentEditable="false"
                      dangerouslySetInnerHTML={{ __html: svg_icon.value }}
                      className="fr-tabbed-content-slider__tab-icon"
                    />
                  )}
                </div>
              </div>
            )
          })}
        </Slider>
        <span
          className={`fr-tabbed-content-slider__tabs-gradient-overlay fr-tabbed-content-slider__tabs-gradient-overlay--right${tabOverlayVariation}`}
        />
      </div>

      <Slider
        asNavFor={nav1}
        {...contentSettings}
        ref={slider2 => slider2 && setNav2(slider2)}
      >
        {content.map(({ elements, id }) => {
          const contentItemElements =
            elements?.content_block?.modular_content[0]?.elements!

          if (!contentItemElements) return null

          if (contentItemElements.hasOwnProperty("service_features")) {
            const {
              service_features,
              pests_covered,
              price_term,
              button,
              price_tiers,
              image,
              pricing_disclaimer,
            } = contentItemElements as ServiceElements
            return (
              <SingleService
                key={id}
                serviceFeatures={service_features}
                pestsCovered={pests_covered}
                priceTerm={price_term?.value[0].codename}
                ctaButton={button?.value[0]}
                priceTiers={price_tiers?.value[0]}
                serviceCount={1}
                featuredImage={image?.value[0]}
                pricingDisclaimer={pricing_disclaimer?.value}
              />
            )
          }

          const { content, buttons, image, style_lists_as_grid } =
            contentItemElements as IContentBlock

          return (
            <TextAndImageContentBlock
              key={id}
              content={content}
              buttons={buttons?.value}
              image={image?.value[0]}
              styleListsAsGrid={
                style_lists_as_grid?.value[0]?.codename === "yes"
              }
              hasBlockPadding={false}
              hasContainerClassName={false}
            />
          )
        })}
      </Slider>
    </>
  )
}
