import React from "react"
import ReactSlickSlider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface SliderProps {
  children: React.ReactNode
  settings?: React.ComponentProps<typeof ReactSlickSlider>
}

export const Slider = ({ children, settings = {} }: SliderProps) => {
  const baseSettings = {
    dots: true,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    ...settings,
  }

  return <ReactSlickSlider {...baseSettings}>{children}</ReactSlickSlider>
}
