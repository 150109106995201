import React from "react"
import { graphql } from "gatsby"
import { NavigationTile } from "./NavigationTile"
import { SingleNagivationTile } from "./navigation-tile-type"
import "./navigation-tile.module.scss"

interface NavigationTilesProps {
  navTiles: SingleNagivationTile[]
  hasAltBackgroundColor: boolean
}

export const NavigationTiles = ({
  navTiles,
  hasAltBackgroundColor,
}: NavigationTilesProps) => {
  // Navigation tiles are a specific type of content block that is returned by Content blocks.
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  return (
    <section
      className={`u-content-block-padding u-clearfix nav-tiles ${alternateBackground}`}
    >
      <div className={`fr-container fr-tiles-container`}>
        <div className={`nav-tiles__wrapper`}>
          {navTiles.map(({ id, elements }) => {
            if (!elements) return
            return <NavigationTile key={id} tile={elements} hasOverlay />
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment NavigationTilesFragment on kontent_item_navigation_block {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      navigation_tiles {
        modular_content {
          ...NavigationTileFragment
        }
      }
    }
  }
`

export const tilesBlockQuery = graphql`
  fragment TilesFragment on kontent_item_tiles_block {
    system {
      type
    }
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      tiles {
        modular_content {
          ...TileFragment
        }
      }
    }
  }
`
