import React from "react"
import { PriceTierElements } from "./price-tier-types"
import "./price-tier.module.scss"

interface PriceTierProps {
  priceTier: PriceTierElements
  priceTerm?: string
  isServiceComparison?: boolean
}

export const PriceTier = ({
  priceTier,
  priceTerm = "per month",
  isServiceComparison = false,
}: PriceTierProps) => {
  const { price, initial_fee } = priceTier
  const getPriceTerm = (priceTerm: string) => {
    switch (priceTerm) {
      case "weekly":
        return "per week"
      case "bi_weekly":
        return "bi-weekly"
      case "bi-monthly":
        return "bi-monthly"
      case "quarterly":
        return "per quarter"
      case "annually":
        return "per year"
      case "one_time":
        return "one time"
      case "per_service":
        return "per service"
      case "monthly":
      default:
        return "per month"
    }
  }
  const getPriceArray = (price: string) => {
    let newPrice = price.split(".")
    if (isServiceComparison)
      return (
        <>
          <p className="service-comparison-price-tier__price">
            {`$${newPrice[0]}`}
            <sup className="service-comparison-price-tier__price--super-script">
              {newPrice[1]}
            </sup>
          </p>
          <p className="service-comparison-price-tier__price-term">
            {getPriceTerm(priceTerm)}
          </p>
        </>
      )
    return (
      <p className="price-tier__price">
        <sup className="price-tier__price--super-script">$</sup>
        {newPrice[0]}
        <sup className="price-tier__price--super-script">.{newPrice[1]}</sup>
      </p>
    )
  }

  if (isServiceComparison) {
    return (
      <div className="service-comparison-price-tier">
        {getPriceArray(price.value)}
      </div>
    )
  } else {
    return (
      <div className="price-tier">
        <div className="price-tier__initial">
          <p>Initial Service</p>
          {getPriceArray(initial_fee.value)}
        </div>
        <div className="price-tier__recurring">
          <p>
            {`${priceTerm.charAt(0).toUpperCase()}${priceTerm.substring(1)} `}
            Price
          </p>
          {getPriceArray(price.value)}
        </div>
      </div>
    )
  }
}
