import React from "react"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"

interface TileOverlayProps {
  baseClassName: string
}

export const TileOverlay = ({ baseClassName }: TileOverlayProps) => {
  const { navTileColorOverlay } = useColorAreaSettings()
  const overrideBackground = navTileColorOverlay
    ? `${navTileColorOverlay}--backgroundColor`
    : ""
  return (
    <div className={`${baseClassName}__overlay ${overrideBackground}`}></div>
  )
}
